import domReady from '@wordpress/dom-ready';

const generateUniqueId = (text, index) => {
  const id = text
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .toLowerCase();
  return document.getElementById(id) ? `${id}-${index}` : id;
};

const createSidebarMenu = () => {
  const headers = document.querySelectorAll('h2');
  const navMenu = document.getElementById('sidebarMenu');

  if (!headers.length || !navMenu) return;

  // Add IDs to headers
  headers.forEach((header, index) => {
    header.id = generateUniqueId(header.textContent, index);
  });

  // Create menu items
  headers.forEach((header) => {
    const listItem = document.createElement('li');
    const link = document.createElement('a');
    link.href = `#${header.id}`;
    link.textContent = header.textContent;
    listItem.appendChild(link);
    listItem.classList.add('sidebar__list__item');
    navMenu.appendChild(listItem);
  });
};

const isElementInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const scrollTop = window.scrollY || document.documentElement.scrollTop;

  return (
    rect.top >= 0 &&
    rect.top <= windowHeight &&
    rect.bottom >= 0 &&
    rect.top <= scrollTop + windowHeight
  );
};

const handleScroll = () => {
  const sidebarMenuLinks = document.querySelectorAll('#sidebarMenu li a');

  sidebarMenuLinks.forEach((link) => {
    const targetElement = document.querySelector(link.getAttribute('href'));
    if (targetElement && isElementInViewport(targetElement)) {
      link.classList.add('sidebar-menu-item--active');
      link.parentElement.classList.add('sidebar__list__item--active');
    } else {
      link.classList.remove('sidebar-menu-item--active');
      link.parentElement.classList.remove('sidebar__list__item--active');
    }
  });
};

domReady(() => {
  const sidebar = document.querySelector('.sidebar');
  const footer = document.querySelector('footer');
  const sidebarNav = sidebar?.querySelector('nav');

  if (!sidebar || !footer || !sidebarNav) return;

  document.body.classList.add('template-page-with-sidebar-menu');
  createSidebarMenu();

  const checkSidebarPosition = () => {
    const footerRect = footer.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;

    if (window.scrollY >= triggerPoint) {
      if (footerRect.top <= viewportHeight) {
        // Footer is in viewport, stick sidebar to bottom
        sidebar.classList.remove('is-fixed');
        sidebar.style.position = 'absolute';
        sidebar.style.top = 'auto';
        sidebar.style.bottom = `120px`;
      } else {
        // Fixed position while scrolling
        sidebar.style.position = '';
        sidebar.style.bottom = '';
        sidebar.style.top = '';
        sidebar.classList.add('is-fixed');
      }
    } else {
      // Reset to initial position
      sidebar.classList.remove('is-fixed');
      sidebar.style.position = '';
      sidebar.style.top = '';
      sidebar.style.bottom = '';
    }
  };

  const triggerPoint = 600;
  let scrollTimeout;
  window.addEventListener('scroll', () => {
    if (scrollTimeout) {
      window.cancelAnimationFrame(scrollTimeout);
    }
    scrollTimeout = window.requestAnimationFrame(() => {
      handleScroll();
      checkSidebarPosition();
    });
  });
});
