import domReady from '@wordpress/dom-ready';

const adjustWidths = () => {
  const dropdowns = document.querySelectorAll(
    '.nav-primary .subnav-wrapper-depth-0'
  );
  const navbarContent = document.querySelector('.main-navbar__inner');
  const navbarPosition = navbarContent.getBoundingClientRect();

  if (0 < dropdowns.length) {
    dropdowns.forEach((dropdown, index) => {
      const dropodwnPosition = dropdown.getBoundingClientRect();

      if (index === dropdowns.length - 1) {
        const dropdownHeading = dropdown.parentElement;
        const headingPosition = dropdownHeading.getBoundingClientRect();
        const xPos = navbarPosition.right - parseFloat(dropdown.offsetWidth);
        const xPos2 = headingPosition.left - xPos;
        dropdown.style.left = xPos2 * -1 + 'px';
      } else {
        const width = navbarPosition.right - dropodwnPosition.left;
        dropdown.style.width = parseFloat(width) + 'px';
      }
    });
  }
};

const adjustWidthsTrottled = throttle(adjustWidths, 500);

function throttle(fn, time) {
  let timeout = null;
  return function () {
    if (timeout) return;
    const context = this;
    const args = arguments;
    const later = () => {
      fn.call(context, ...args);
      timeout = null;
    };
    timeout = setTimeout(later, time);
  };
}

domReady(() => {
  adjustWidths();

  const mainNavbar = document.querySelector('.main-navbar');
  const items = document.querySelectorAll('.nav-primary > .nav-item.dropdown');

  if (0 < items.length) {
    items.forEach((item) => {
      item.addEventListener('mouseenter', () => {
        mainNavbar.classList.add('has-dropdown-open');
      });
      item.addEventListener('mouseleave', () => {
        mainNavbar.classList.remove('has-dropdown-open');
      });
    });
  }
});

window.addEventListener('resize', adjustWidthsTrottled);
